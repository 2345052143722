.teammembers .clickcustomform {
    padding-left: 10px;
    padding-right: 10px;
}

.teammembers .clickcustomform .team {
    background: rgb(40 41 46);
    position: relative;
    display: inherit;
}

.teammembers .clickcustomform .team i {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 5px;
    background: #111217;
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: all ease-in-out 0.5s;
}

.teammembers .clickcustomform .team:hover .team-des {
    transform: translatey(0px);
}

.teammembers .clickcustomform .team:hover i {
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 35px;
    align-items: center;
    justify-content: center;
    visibility: visible;
    opacity: 1;
    transform: translate(0px, 0px);
}

[data-theme='light'] .teammembers .clickcustomform .team-des {
    background-image: linear-gradient(90.05deg, rgba(234, 234, 234, 1) 52.98%, rgba(234, 234, 234, 1) 99.96%);
}

.teammembers .clickcustomform .team-des {
    padding: 15px 10px;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    background: rgba(34, 36, 45, 1);
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
}

.teammembers .clickcustomform .team-des h3 {
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
}

.teammembers .clickcustomform .team-des p {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
}
[data-theme = 'light'] .teammembers .advisory .clickcustomform .team:hover .team-des p, [data-theme = 'light'] .teammembers .advisory .clickcustomform .team:hover .team-des h3 {
    color: #fff;
}
[data-theme = 'light'] .teammembers .clickcustomform .team-des p, [data-theme = 'light'] .teammembers .clickcustomform .team-des h3 {
    color: #000;
}
.teammembers .clickcustomform .team-des i {}

.teammembers .clickcustomform .team-des i img {
    width: 17px;
}

.teammembers .slick-prev,
.teammembers .slick-next {
    top: -14%;
    transform: translate(0, -14%);
}

.teammembers .slick-next {
    right: 10px;
}

.teammembers .slick-prev {
    left: auto;
    right: 70px;
}

.teammembers .heading-style h4 {
    font-weight: 900;
    color: var(--white);
    font-family: var(--FuturaBold);
    margin-bottom: -20px;
}

.teammembers .slick-slide figure img {
    width: 100%;
    min-height: 413px;
    padding-bottom: 110px;
    object-fit: cover;
}

@media (max-width: 991px) {
    .teammembers .slick-next {
        right: 24px;
    }

    .teammembers .clickcustomform .team-des h3 {
        font-size: 14px;
    }

    .teammembers .clickcustomform .team-des p {
        font-size: 12px;
    }

    .teammembers .slick-slide figure img {
        padding-bottom: 90px;
    }

    .teammembers .clickcustomform .team-des {
        min-height: 90px;
    }
}

@media (max-width: 767px) {
    .teammembers .slick-slide figure img {
        min-height: auto;
    }

    .teammembers .heading-style h4 {
        margin-bottom: 0;
    }
}